import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authStyles from './auth.module.css';
import { BsFacebook, BsInstagram } from "react-icons/bs";


const AuthFooter = () => {
  
  return (

    <footer>
       <Container fluid className={`mainPad ${authStyles.footerBackgrounddiv}`}>
        <Row>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={12} lg="auto" className={authStyles.footerLinksAlignment}>
              <img src="/Content/Images/Svgs/logo_background.svg" alt="Logo" width="170" height="170" className="mr-2 mb-4 mt-1" />
            </Col>
            <Col xs={12} lg="auto" className={authStyles.footerLinksAlignment}>
              <Button className={`${authStyles.footerbuttonYellow}`} size="sm">
                Company
              </Button>
              <Link to="/info/about-us">
                <button type="button" className={`mt-4 ${authStyles.footerClickA}`}>About Us</button>
              </Link>
              <Link to="/info/mandatory-details">
                <button type="button" className={`mt-3 ${authStyles.footerClickA}`}>Join</button>&nbsp;
              </Link>
            </Col>
            {/* <Col xs={12} lg="auto" className={authStyles.footerLinksAlignment}>
              <Button className={`${authStyles.footerbuttonYellow}`} size="sm">
                FAQ's
              </Button>
              <Link to="/host-faqs" style={{textDecoration: 'none'}}>
              <button type="button" className={`mt-4 ${authStyles.footerClickA}`}>Host</button>
              </Link>
              <Link to="/buyer-faqs" style={{textDecoration: 'none'}}>
              <button type="button" className={`mt-3 ${authStyles.footerClickA}`}>Buyer</button>&nbsp;
              </Link>
            </Col> */}
            <Col xs={12} lg="auto" className={authStyles.footerLinksAlignment}>
              <Button className={`${authStyles.footerbuttonYellow}`} size="sm">
                Legal
              </Button>
              <Link to="/info/privacy-policy" >
                <button type="button" className={`mt-4 ${authStyles.footerClickA}`}>Privacy Policy</button>
              </Link>
              <Link to="/info/terms-and-conditions">
                <button type="button" className={`mt-3 ${authStyles.footerClickA}`}>Terms & Conditions</button>&nbsp;
              </Link>
            </Col>
            <Col xs={12} lg="auto" className={authStyles.footerLinksAlignment}>
              <Button className={`${authStyles.footerbuttonYellow}`} size="sm">
                Follow
              </Button>
              <Button className={`mb-5 ${authStyles.footerbuttonYellow}`} style={{visibility: 'hidden'}} size="sm"></Button>
              <div className='text-center mt-2'>
                <Link to="https://www.facebook.com/hapifyr" target="_blank" rel="noopener noreferrer">
                  <BsFacebook style={{color: 'white'}}/> &nbsp;&nbsp;
                </Link>
                <Link to="https://www.instagram.com/hapifyr/" target="_blank" rel="noopener noreferrer">
                <BsInstagram style={{color: 'white'}} /> &nbsp;&nbsp;
                </Link>
              </div>
            </Col>
            <Col xs={12} lg="auto"></Col>
          
          </Row>

          <hr height="2" />

          <Row className="d-flex align-items-center justify-content-center">
          <Col xs={12} lg="auto">
            <div className={authStyles.footerReserved}>
              <p className="p-0 m-0">©2024 MingleIn Pty Ltd. All rights reserved.</p> 
              
              <p>Hapifyr<span style={{ verticalAlign: 'super',fontSize: '7px' }}>TM</span> is a Trademark of MingleIn Pty Ltd ABN 5164567837.</p>
            </div>
            
             </Col>
          </Row>

        </Row>

      </Container>
    </footer>
  );
};


export default AuthFooter;

import { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import profileStyles from './leftComponent.module.css'; 
import { Link } from 'react-router-dom';
import Filters from './Filters';


const FiltersProfile = ({ filters, onFilterChange }) => {

    const [userProfileData, setUserProfileData] = useState(null);

    useEffect(() => {
      // Retrieve data from localStorage
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        const parsedData = JSON.parse(profileData);
        setUserProfileData(parsedData);
      }
    }, []);
   

  return (
    <Container className={`noPadding boxBody ${profileStyles.bottomBorder}`}>
      <Row className={`${profileStyles.userProfileInnerBox}`}>
      {userProfileData ? (
          <Link to='/profile/update_profile_picture'>
            <Row className={`${profileStyles.userProfileOuterBox}`}>
              
              <Col xs="auto">
                <Image
                  src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                  alt="Profile" roundedCircle
                  className={`roundedUser ${profileStyles.profileImage}`}
                />
              </Col>
              <Col xs="auto">
                <h3 className={profileStyles.profileName}>&nbsp;{userProfileData.name}&nbsp;</h3>
              </Col>
              <Col xs="auto">
                <p className={profileStyles.profileBio}>{userProfileData.bio}</p>
                
              </Col>
            </Row>
          </Link>
        
        ) : (
          <Row className={`text-center ${profileStyles.userProfileBox}`}>  
            <Image
              src="/Content/Images/empty_user.jpg"
              alt="Profile"
              roundedCircle
              className={profileStyles.profileImage}
            />
            <p>No profile Data</p>
          </Row>
        )}
      </Row> 

      <Row>
        <Filters filters={filters} onFilterChange={onFilterChange} />
      </Row>
  </Container>
      
  );
};

export default FiltersProfile;

import { useState, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col, Container, Badge, Tab, Tabs } from 'react-bootstrap';
import styles from './followers.module.css';
import FollowersList from './FollowersList';
import FollowingsList from './FollowingsList';
import BlockedUsersList from './BlockedUsersList';
import AuthContext from '../Context/AuthContext';
import userServices from '../../Services/userServices';


const MyFollowers = ({ type }) => {
    
    // console.log("type::: ", type);
    const [activeTab, setActiveTab] = useState(type);
    const { setIsLoading } = useContext(AuthContext);
    const [followersList, setFollowersList] = useState([]);
    const [followingsList, setFollowingsList] = useState([]);
    const [blockedList, setBlockedList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);

                // Fetch followers details
                const followersResponse = await userServices.fetchFollowersDetails();
                if (followersResponse !== null && followersResponse.statusCode === 200 && followersResponse.data !== null && followersResponse.data !== "") {
                    setFollowersList(followersResponse.data);
                }
    
                // Fetch followings details
                const followingsResponse = await userServices.fetchFollowingsDetails();
                if (followingsResponse !== null && followingsResponse.statusCode === 200 && followingsResponse.data !== null && followingsResponse.data !== "") {
                    setFollowingsList(followingsResponse.data);
                }

                // Fetch blocked users details
                const blockedResponse = await userServices.fetchBlockedUsersDetails();
                if (blockedResponse !== null && blockedResponse.statusCode === 200 && blockedResponse.data !== null && blockedResponse.data !== "") {
                    setBlockedList(blockedResponse.data);
                }
    
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setIsLoading(false);
            }
        }
    
        fetchData();
        
    }, [setIsLoading]);


    const followHandlerOptimizer = async (userId, followType, type) => {
        if (followType === 'followers') {
            let followersDetails = [...followersList];
            // Filter out the user whose userId matches the given userId (remaining data)
            let filteredFollowersList = followersDetails.filter(i => i.userId !== userId);
            // Find the user that is being removed (removed data)
            let removedFollower = followersDetails.find(i => i.userId === userId);
            if (type === 'block') {
                // Add the removed follower to the blocked list
                setBlockedList((prevBlockedList) => [...prevBlockedList, removedFollower]);
            } 
            // Update the followers list regardless of type
            setFollowersList(filteredFollowersList);
    
        } else if (followType === 'followings') {
            let followingsDetails = [...followingsList];
            let filteredFollowingsList = followingsDetails.filter(i => i.userId !== userId);
            let removedFollowing = followingsDetails.find(i => i.userId === userId);
    
            if (type === 'block') {
                setBlockedList((prevBlockedList) => [...prevBlockedList, removedFollowing]);
            }
            setFollowingsList(filteredFollowingsList);
    
        } else if (followType === 'blockedUsers') {
            let blockedDetails = [...blockedList];
            let filteredBlockedList = blockedDetails.filter(i => i.userId !== userId);
            // let removedBlockedUser = blockedDetails.find(i => i.userId === userId);
            setBlockedList(filteredBlockedList);
        }
    
        try {
            await userOptimisticHandler(userId, type);
        } catch (error) {
            console.error("Failed to remove user from server:", error);
            // Revert the list if the API call fails
            setFollowersList(followersList);
            setFollowingsList(followingsList);
            setBlockedList(blockedList);
        }
    };
    
    
    const userOptimisticHandler = async (userId, type) => {
        if (userId === "" || userId === null) {
            alert("Please provide the UserId first.");
            return;
        }
    
        try {
            setIsLoading(true);
            if (type === 'remove') {
                await userServices.removeUser(userId);
            } else if (type === 'block') {
                await userServices.blockUser(userId);
            } else if (type === 'unblock') {
                await userServices.unblockUser(userId);
            } else if (type === 'unfollow') {
                await userServices.unfollowUser(userId, true);
            }
    
        } catch (error) {
            throw new Error("API Error: " + error.message);  // Propagate the error
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <Container fluid>

            <Row className='mb-3'>
                <h2 className={`mainHeaderStyle`}>My Followers
                    <Badge pill bg="warning" text="dark" className="mainHeaderBadgeStyle">{followersList.length || 0}</Badge>
                </h2>
            </Row>

            <Row className={`minPadding ${styles.follwersSection}`}>

                <Col lg={12} md={12} sm={12} xs={12} className={`${styles.follwersBlocks}`}>
                    <div>
                        <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className={styles.customTabHeaders}
                        mountOnEnter
                        unmountOnExit
                        >
                            <Tab eventKey="followers"
                                title={<span className={`${styles.customTabHeader} 
                                ${activeTab === 'followers' ? styles.customTabClickActive : ''}`}> Followers </span> } 
                            />
                            <Tab eventKey="followings"
                                title={<span className={`${styles.customTabHeader}
                                ${activeTab === 'followings' ? styles.customTabClickActive : ''}`}> Followings </span> } 
                            />
                            <Tab eventKey="blockedUsers"
                                title={<span className={`${styles.customTabHeader} 
                                ${activeTab === 'blockedUsers' ? styles.customTabClickActive : ''}`}> Blocked </span> } 
                            />
                        </Tabs>
                    </div>
                    {/* <div>
                        <Link to="/followers/add-connections">
                            <Button>Add New</Button>
                        </Link>
                    </div> */}
                </Col>
            
                <Col lg={12} md={12} sm={12} xs={12} className={`mt-2 ${styles.followerHeight}`}>
                   
                    {activeTab === 'followers' && <FollowersList followers={followersList} handleFollowers={followHandlerOptimizer} />}
                    {activeTab === 'followings' && <FollowingsList followings={followingsList} handleFollowers={followHandlerOptimizer} />}
                    {activeTab === 'blockedUsers' && <BlockedUsersList blockedUsers={blockedList} handleFollowers={followHandlerOptimizer} />}

                </Col>

            </Row>

        </Container>
    );
};

export default MyFollowers;

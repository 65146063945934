// import { useState } from 'react';
import FiltersProfile from './LeftComponents/FiltersProfile';
import HomeOverview from '../BaseComponents/HomeOverview';
import mainStyles from './Main.module.css';
// import Filters from './LeftComponents/Filters';
// import ProductsforSale from './LeftComponents/ProductsforSale';
// import PurchasedProducts from './LeftComponents/PurchasedProducts';
// import Fallowers from './LeftComponents/Fallowers';


const Leftsidebar = ({ type, filters, onFilterChange }) => {

  return (
    <div className={mainStyles.stickySidebar}>
      <div className={mainStyles.leftComponentScroll}>

        <FiltersProfile filters={filters} onFilterChange={onFilterChange} />
        <HomeOverview type={type} />
        <br /><br /><br />
      
      </div>
    </div>
    
  );
};

export default Leftsidebar;
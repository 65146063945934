import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Chat.module.css';


const MessagingFooter = () => {
  
    return (
        <Row className={`${styles.MsgfooterSec}`}>
            <Col xs="auto">
                <Link to="/info/about-us" className={styles.LinkClicks}>About Us</Link> 
            </Col>
            <Col xs="auto">
                <Link to="/info/privacy-policy" className={styles.LinkClicks}>Privacy Policy</Link> & <Link to="/general/terms-and-conditions" className={styles.LinkClicks}>Terms of Service</Link>
            </Col>
         </Row>
  );
};

export default MessagingFooter;

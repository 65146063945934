import { useState, useEffect } from 'react';
import Feed from './Feed/Feed';
import userServices from '../../Services/userServices';
import { BeatLoader } from 'react-spinners';
import styles from './Main.module.css'; // Ensure to import the styles


const Content = ({ filters }) => {

  const [feeds, setFeeds] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [stopFetch, setStopFetch] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(null); // Track the current active filters

  
  const fetchData = async (index, activeFilters) => {
    try {
      setIsLoading(true);
      const response = await userServices.getUserFeeds(index, activeFilters);
      if (response.statusCode === 200) {
        const json = await response.data;
        if (json.length === 0) {
          setStopFetch(true); // No more data to fetch
        } else {
          setFeeds((prevFeeds) => (index === 1 ? json : [...prevFeeds, ...json])); // Replace or append feeds based on index
        }
      } else {
        setStopFetch(true);
      }
    } catch (error) {
      console.log(error);
      setStopFetch(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data whenever filters or startIndex change
  useEffect(() => {
    if (!stopFetch) {
      fetchData(startIndex, currentFilters);
    }
  }, [startIndex, stopFetch, currentFilters]);

  // Handle filter changes
  useEffect(() => {
    if (filters && (filters.category || filters.workshop || filters.price || filters.rating || filters.length || filters.forSale || filters.reviewCount || filters.user)) {
      setStartIndex(1); // Reset to first page
      setStopFetch(false); // Enable fetching
      setFeeds([]); // Clear feeds when applying new filters
      setCurrentFilters(filters); // Update current filters
    } else {
      setCurrentFilters(null); // Clear filters when none are applied
    }
  }, [filters]);

  // Handle infinite scrolling for pagination
  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 1 && !isLoading && !stopFetch) {
        setStartIndex((prevIndex) => prevIndex + 1); // Increment the index for the next page
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, stopFetch]);


  return (
    <div className='row minPadding'>
      {feeds.length > 0 ? (
        <Feed feeds={feeds} setFeeds={setFeeds} />
      ) : (
        !isLoading && (
          <div className={styles.feedsContainerBox}>
            <label>No feeds available.</label>
          </div>
        )
      )}
      {isLoading && (
        <div className={styles.feedsContainerBox}>
          <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
          <label> Loading... </label>
        </div>
      )}
    </div>
  );
};

export default Content;

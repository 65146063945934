import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useCart } from './CartContext';
import { Container, Row, Col, Button, Badge, Nav, Navbar, NavDropdown, Image, Offcanvas } from "react-bootstrap";
import headerStyles from './header.module.css';
import CartPopup from "../Buy/Cart/CartPopup";
import cartServices from "../../Services/cartServices";
import SearchBar from "./SearchBar";
import AuthContext from "../Context/AuthContext";


const Header = ({ loginStatus }) => {
  const { state, dispatch } = useCart();
  const { logout } = useContext(AuthContext); // Use useContext to access logout function
  const [selectedTab, setSelectedTab] = useState("");
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const response = await cartServices.viewCartData();
        if (response.statusCode === 200 && response.data?.cartItems !== null) {
          dispatch({ type: 'SET_CART_COUNT', payload: response.data.noOfItems });
        }
      } catch (error) {
        console.error('API Error:', error);
      }
    }
    fetchCartCount();
  }, [dispatch]);

  const userProfileDataString = localStorage.getItem('userProfileData');
  const userProfileData = JSON.parse(userProfileDataString);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleTab = (tab) => {
    setSelectedTab(tab);
  }

  const handleCartIconClick = async () => {
    setShowCartPopup(true);
  };

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };


  return (
    <>
      <Navbar className={`mainPad ${headerStyles.navbarHeader}`} expand="lg" fixed="top">
        <Container fluid className={headerStyles.navbarContainer}>
          
          <Col xs={1} lg={0} className={`d-lg-none d-flex align-items-center`}>
            <Button variant="link" className={`p-0 ${headerStyles.responsiveCanvasUser}`} onClick={toggleOffcanvas}>
              <Image
                src={userProfileData && userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                width={35} 
                height={35}
                alt="Profile"
                roundedCircle
                className="roundedUser"
              />
            </Button>
          </Col>
          <Col xs={0} lg={1} className={headerStyles.responsiveDisplay}>
            <Link to="/home">
              <img src="/Content/Images/Svgs/logo_background.svg" alt="Logo" className={`${headerStyles.logo}`} />
            </Link>
          </Col>
          <Col xs={1} lg={2}></Col>
          <Col xs={8} lg={4}>
            <SearchBar />
          </Col>
          <Col xs={1} lg={1}></Col>
          <Col xs={0} lg={4} className={headerStyles.responsiveDisplay}>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              {loginStatus === 'loggedIn' && (
                <Nav className={`ml-auto`}>
                  <Row className="justify-content-between">
                    <Col lg={4} className="d-none d-lg-flex align-items-center justify-content-center">
                      <Nav.Link href="/seller/dashboard" onClick={() => handleTab('Sell')} className={`${headerStyles.Links} ${selectedTab === 'Sell' ? headerStyles.selectedTab : ""}`}>
                        Host
                      </Nav.Link>
                      <Nav.Link href="/engage/dashboard" onClick={() => handleTab('Engage')} className={`${headerStyles.Links} ${selectedTab === 'Engage' ? headerStyles.selectedTab : ""}`}>
                        Engage
                      </Nav.Link>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={3} className="d-flex align-items-center justify-content-center">
                      <Nav.Link onClick={handleCartIconClick} style={{ position: 'relative' }}>
                        <img src="/Content/Images/icons/cartblue.png" alt="Cart_icon" className={`${headerStyles.cartlogo}`} />
                        {state.cartCount >= 0 && (
                          <Badge className={`${headerStyles.cartCountBadge}`}>{state.cartCount}</Badge>
                        )}
                      </Nav.Link>
                    </Col>
                    <Col lg={4} className="d-none d-lg-flex align-items-center justify-content-center">
                      <NavDropdown
                        title={
                          <Image 
                            src={userProfileData && userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                            width={35} height={35}
                            alt="Profile" 
                            roundedCircle
                            className="roundedUser dropdown-trigger"
                            onClick={toggleDropdown}
                          />
                        }
                        id="navbarScrollingDropdown" align="end"
                        className="customdropdownmenu"
                      >
                        <NavDropdown.Item>
                          {userProfileData !== null ? (
                            <div className={headerStyles.UserProfileBoxsize}>
                              <Row className="d-flex align-items-start justify-content-between">
                                <Col md={4}>
                                  <Image 
                                    src={userProfileData && userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                                    width={45} height={45}
                                    alt="Profile"
                                    roundedCircle
                                    className={`dropdown-trigger mt-2 ${headerStyles.roundedUserProfile}`}
                                    onClick={toggleDropdown}
                                  />
                                </Col>
                                <Col md={9}>
                                  <label className={headerStyles.profileUsername}>{userProfileData.name}</label>
                                  <p className={headerStyles.profileUserBio}>{userProfileData.bio}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Link to="/profile/update_profile_picture">
                                  <button type="button" className={`${headerStyles.viewProfileBtn}`}>View Profile</button>
                                </Link>
                              </Row>
                            </div>
                          ) : null}
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className={headerStyles.profileLogoutButtons}>
                          <Link className="link" style={{color: '#40637D'}} onClick={logout}>Log out</Link>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Col>
                  </Row>
                </Nav>
              )}
            </Navbar.Collapse>
          </Col>
          <Col xs={1} lg={0}>
            {loginStatus === 'loggedIn' && (
              <div className="d-lg-none">
                <Nav.Link onClick={handleCartIconClick} style={{ position: 'relative' }}>
                  <img src="/Content/Images/icons/cartblue.png" alt="Cart_icon" className={`${headerStyles.cartlogo}`} />
                  {state.cartCount >= 0 && (
                    <Badge className={`${headerStyles.cartCountBadge}`}>{state.cartCount}</Badge>
                  )}
                </Nav.Link>
              </div>
            )}
          </Col>
        </Container>
      </Navbar> 
      
      <CartPopup showCartPopup={showCartPopup} onClose={() => setShowCartPopup(false)} />
      <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} placement="start">
        <Offcanvas.Header closeButton className={headerStyles.OffCanvasClose}>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {userProfileData !== null && (
            <div className={headerStyles.UserProfileBoxsize}>
              <Row className="d-flex align-items-start justify-content-between">
                <Col md={4}>
                  <Image
                    src={userProfileData && userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                    alt="Profile"
                    roundedCircle
                    className={`mt-2 ${headerStyles.OffCanvasUserProfile}`}
                  />
                </Col>
                <Col md={9}>
                  <label className={headerStyles.OffCanvasUsername}>{userProfileData.name}</label>
                  <p className={headerStyles.OffCanvasUserBio}>{userProfileData.bio}</p>
                </Col>
              </Row>
              <NavDropdown.Item className={headerStyles.OffCanvasUserOverview}>
                <Link className="link" style={{color: '#40637D'}}>Overview</Link>
              </NavDropdown.Item>
              <Row>
                <Link to="/profile/update_profile_picture">
                  <button type="button" className={`${headerStyles.viewProfileBtn}`}>View Profile</button>
                </Link>
              </Row>
            </div>
          )}
          <NavDropdown.Divider />
          <NavDropdown.Item className={headerStyles.OffCanvasUserOverview}>
            <Link className="link" style={{color: '#40637D'}} onClick={logout}>Log out</Link>
          </NavDropdown.Item>
        </Offcanvas.Body>
      </Offcanvas>

      <Navbar fixed="bottom" className={`BottomNav d-lg-none ${headerStyles.responsiveBottomNavbar}`}>
        <Nav className="w-100 justify-content-around">
          <Nav.Link href="/chat" className={`${headerStyles.Links}`}>
            Message
          </Nav.Link>
          <Nav.Link href="/seller/dashboard" onClick={() => handleTab('Sell')} className={`${headerStyles.Links} ${selectedTab === 'Sell' ? headerStyles.selectedTab : ""}`}>
            Host
          </Nav.Link>
          <Nav.Link href="/engage/dashboard" onClick={() => handleTab('Engage')} className={`${headerStyles.Links} ${selectedTab === 'Engage' ? headerStyles.selectedTab : ""}`}>
            Engage
          </Nav.Link>
          <Nav.Link href="/home" className={`${headerStyles.Links}`}>
            Home
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
};

export default Header;

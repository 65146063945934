import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Navbar/Header';
import HubDashboardDetails from './Engage/HubDashboardDetails';


const ExperienceHubDashboard = () => {

  const [loginStatus] = useState('loggedIn');
  const { engageType } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [engageType]);


  return (

    <div className="ExperienceHubDashboardDiv">
      
      <Header loginStatus={loginStatus} />
      
      <HubDashboardDetails type={engageType} />
       
    </div>

  );
}

export default ExperienceHubDashboard;

import { Link } from 'react-router-dom';
import { Row, Image, Container } from 'react-bootstrap';
import Tooltip from '../BaseComponents/Tooltip';
import hubStyles from './hub.module.css';


const PurchasedExperiences = ({ experiences, handleUser }) => {
  
  
  return (
    <Container className={`feedBodyBorder noPadding`}>
        {experiences && experiences.length > 0 ? (
          experiences.map((experience, index) => (
            <Row className={`${hubStyles.PurchasedContainer}`} key={index} >
              <Row className={`${hubStyles.PurchasedExperiencesRow} mb-3`}>
                <div className={hubStyles.PWContainerdiv}>
                  <Link to={`/experiencehub/experience/${experience.id}`} >
                    <Image
                      src={experience.icon || '/Content/Images/General/no_thumbnail.png'}
                      alt="PW_thumbnail"
                      fluid
                      roundedCircle
                      className={hubStyles.PWThumbnail}
                    />
                  </Link>
                  <div className={hubStyles.PWContent}>
                    <Image
                      src="/Content/Images/icons/document-text.png"
                      className={hubStyles.PWDescTextImgSize}
                    />
                    &nbsp;
                    <p className={hubStyles.PWContentTextColorFont} title={experience.desc} >{experience.desc}</p>
                  </div>
                  
                  <div className={hubStyles.PWContentSide}>
                    <div>
                      <div className="followUsersetupDiv mb-2">
                        <Tooltip
                          content={
                            experience.isFollowing
                              ? `Unfollow ${experience.contentOwnerName}`
                              : `Follow ${experience.contentOwnerName}`
                          }
                        >
                          <Link to={`/seller/public-profile/${experience.contentOwner}`}>
                            <div className={hubStyles.PWIconwithCountsdiv}>
                              <div className={hubStyles.PWIconFollowAdddiv}>
                                <Image
                                  src={experience.contentOwnerImg || '/Content/Images/empty_user.jpg'}
                                  className={hubStyles.PWIconFollowImg}
                                />
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleUser(experience.contentOwner); // Ensure FollowPWUser is correctly defined
                                  }}
                                  className={hubStyles.PWIconFollowAddIconContainer}
                                >
                                  <Image
                                    src={
                                      experience.isFollowing
                                        ? '/Content/Images/icons/icons-cancel.png'
                                        : '/Content/Images/icons/icons-plus.png'
                                    }
                                    className={hubStyles.PWIconFollowAddIcon}
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </Row>
          ))
        ) : (
          <div className={hubStyles.noPurchasesContainer}>
            You have not purchased any workshops.
          </div>
        )}
    </Container>
  );
};

export default PurchasedExperiences;

import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Leftsidebar from "./Leftsidebar";
import Content from "./Content";
import SendBird from '../SendBird';
import styles from './Main.module.css';


const MainPart = () => {
  
  // Move filter state here
  const [filters, setFilters] = useState({
    category: "",
    workshop: "",
    price: "",
    rating: "",
    boasts: "",
    length: "",
    forSale: "",
    reviewCount: "",
    user: "",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    // console.log("Updated Filters in MainPart:", newFilters);
  };

  return (
    <main className="MainpartSetup HomeViewportHt">
       
      <Row className={`mainPad stickySidebar`}>
        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <Leftsidebar filters={filters} onFilterChange={handleFilterChange} />
        </Col>

        <Col xs={12} lg={6}>
          <Content filters={filters} />
        </Col>

        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <SendBird />
        </Col>

      </Row>

    </main>
    
  );
};

export default MainPart;
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Row, Col, Button, Container } from 'react-bootstrap';
import authStyles from './auth.module.css';


const AuthHeader = () => {


  return (
    <Navbar className={`${authStyles.AuthHeader}`} expand="lg" fixed="top" collapseOnSelect>
      <Container fluid>
        <Row className="w-100 align-items-center p-2">
          <Col xs="6" lg="auto" className="d-flex justify-content-start">
            <Navbar.Brand href="/">
              <img src="/Content/Images/Svgs/logo_background.svg" alt="Logo" className={authStyles.authLogo} />
            </Navbar.Brand>
          </Col>
          <Col xs="6" className="d-flex justify-content-end d-lg-none">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Col>
          <Col lg="auto" className="d-none d-lg-flex justify-content-end ms-auto">
            <Nav className={`ms-auto ${authStyles.HeaderNavLinks}`}>
              <Link to="/info/about-us">
                <Button variant="white" size="md" className={authStyles.authHeaderrowBtns}>
                  About Us
                </Button>
              </Link>
              <Link to="/signup/mandatory-details">
                <Button variant="white" size="md" className={authStyles.authHeaderrowBtns}>
                  Sign Up
                </Button>
              </Link>
              <Link to="/signin/form">
                <Button variant="white" size="md" className={authStyles.authHeaderrowBtns}>
                  Sign In
                </Button>
              </Link>
            </Nav>
          </Col>
        </Row>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`ms-auto d-lg-none ${authStyles.HeaderNavLinks}`}>
            <Link to="/signup/mandatory-details">
              <Button variant="white" size="md" className={authStyles.authHeaderrowBtns}>
                Sign Up
              </Button>
            </Link>
            <Link to="/signin/form">
              <Button variant="white" size="md" className={authStyles.authHeaderrowBtns}>
                Sign In
              </Button>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AuthHeader;

import React, { createContext, useState, useCallback } from 'react';
import loginApiServices from '../../Services/loginApiServices';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const logout = useCallback(async () => {
    setIsLoading(true);
    try {
      await loginApiServices.userLogout();
      
    } catch (error) {
      console.error("Failed to log out properly:", error);
      alert("Logout failed due to API failure, please contact the support team.");
    } finally {
      localStorage.clear();
      setIsLoading(false);
      window.location.href = '/signin/form';
    }
  }, []);

  return (
    <AuthContext.Provider value={{ logout, isLoading, setIsLoading  }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
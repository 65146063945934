import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsFillHddRackFill, BsCollectionPlay } from "react-icons/bs";
import styles from './base.module.css';


const EngageActions = ({ type }) => {


  return (
    <Container className='noPadding stickySidebarOverview'>
      <Row className={`d-flex flex-column align-items-center ${styles.EngageActionsBox} boxBody`}>
        <br/>
        <h5 className={`mb-3 ${styles.ActionHeader}`}>Actions</h5>
        
          <Row className='mb-3'>
            <Row>
              {type !== "past-workshops" && type !== "upcoming-workshops" && type !== "unpublished-workshops" ? (
                <Link to="/engage/dashboard">
                  <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                      <button className={`btn ${styles.ActionClickBtns} ${styles.ActionClickTextActive}`}>
                        <BsFillHddRackFill style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Dashboard
                      </button>
                  </Row>
                </Link>
              ) : (
                <Link to="/engage/dashboard">
                  <Row className={`mb-2 ${styles.ActionClickRows}`}>
                      <button className={`btn ${styles.ActionClickBtns}`}>
                        <BsFillHddRackFill style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Dashboard
                      </button>
                  </Row>
                </Link>
              )}
            </Row>
          
            <Row>
              {type === "past-workshops" ? (
                <Link to="/engage/past-workshops">
                      <Row className={`mb-2 ${styles.ActionClickRowsActive} ${styles.activeAction}`}>
                          <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtnsActive}`}>
                              <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Past Workshops
                              </div>
                          </button>
                      </Row>
                </Link>
              ) : (
                <Link to="/engage/past-workshops">
                      <Row className={`mb-2 ${styles.ActionClickRows}`}>
                          <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                              <div className="d-flex align-items-center">
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Past Workshops
                              </div>
                          </button>
                      </Row>
                </Link>
              )}
            </Row>

            <Row>
              {type === "upcoming-workshops" ? (
                <Link to="/engage/past-workshops">
                      <Row className={`mb-2 ${styles.ActionClickRowsActive} ${styles.activeAction}`}>
                          <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtnsActive}`}>
                              <div className={`d-flex align-items-center ${styles.ActionClickTextActive}`}>
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Upcoming Workshops
                              </div>
                          </button>
                      </Row>
                </Link>
              ) : (
                <Link to="/engage/upcoming-workshops">
                      <Row className={`mb-2 ${styles.ActionClickRows}`}>
                          <button className={`btn d-flex align-items-center justify-content-between ${styles.ActionClickBtns}`}>
                              <div className="d-flex align-items-center">
                                <BsCollectionPlay style={{backgroundColor: 'darkgray'}} />&nbsp;&nbsp; Upcoming Workshops
                              </div>
                          </button>
                      </Row>
                </Link>
              )}
            </Row>

          </Row>

      </Row>
    </Container>
  );
};


export default EngageActions;

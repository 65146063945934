import axiosAPI from "./axiosAPI";


const MessagingServices = {


  fetchRoomIdtoChat: async (sellerId) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/message/create`, {
        chatWith: sellerId,
      });
      // console.log("fetchRoomIdtoChat :: API ::", response.data);
      
      return response.data;
    } catch (error) {
      console.error("fetchRoomIdtoChat API Error:", error);
      throw error;
    }
  },

  
  searchMessagingUsers: async (input) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/profile/search`,{
        name: input,
        segment: "message",
      });
      // console.log("in MessagingServices searchMessagingUsers: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API searchMessagingUsers Error:", error);
      throw error;
    }
  },

  fetchUsersforCreateGroup: async () => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/profile/search`,{
        segment: "message",
        listUsers: true,
    });
      // console.log("in fetchUsersforCreateGroup::", response.data);
      return response.data;

    } catch (error) {
      console.error("API fetchUsersforCreateGroup Error:", error);
      throw error;
    }
  },

  createGroupChannel: async (selectedUsers,groupName) => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.post(`/message/group`,{
        participants: selectedUsers,
        groupName: groupName,
      });
      // console.log("in MessagingServices createGroupChannel: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API fetchChatData Error:", error);
      throw error;
    }
  },


  fetchChatData: async (userId) => {
    try {
    await axiosAPI.validateToken();
    const response = await axiosAPI.axiosAuth.post(`/message/chat`,{
        receipeint: userId,
      });
      // console.log("in MessagingServices fetchChatData: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API fetchChatData Error:", error);
      throw error;
    }
  },

  fetchChatLists: async () => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/message/lists`);
      // console.log("in MessagingServices fetchChatData: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

  fetchBlockedUserslist: async () => {
    try {
      await axiosAPI.validateToken();
      const response = await axiosAPI.axiosAuth.get(`/profile/blocked`);
      // console.log("in MessagingServices fetchBlockedUserslist::", response.data);
      return response.data;

    } catch (error) {
      console.error("fetchBlockedUserslist API Error:", error);
      throw error;
    }
  },

};


export default MessagingServices;
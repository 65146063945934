import axiosAPI from "./axiosAPI";


const hubServices = {

  saveBuyerReviewPost: async (form) => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
     const response = await axiosAPI.axiosAuth.post(`/feedback/review`, form,  {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'multipart/form-data',
        },
      });
      //console.log("saveBuyerReviewPost: ", response);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  fetchPurchasedExperiences: async () => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.get(`/experiences/items`, {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'application/json',
        },
      });

      //console.log("fetchPurchasedExperiences: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },


  fetchExpiredExperiences: async () => {
    try {
      await axiosAPI.validateToken();
      const accessToken = localStorage.getItem('accessToken');
      const token = `Bearer ${accessToken}`;
      const response = await axiosAPI.axiosAuth.get(`/experiences/expired-items`, {
        headers: {
          'Authorization': token, // Include the "Authorization" header
          'Content-Type': 'application/json',
        },
      });

      //console.log("fetchExpiredExperiences: :", response.data);
      return response.data;

    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  },

};


export default hubServices;
import { Row, Col } from 'react-bootstrap';
import Leftsidebar from '../Main/Leftsidebar';
import MyFollowers from './MyFollowers';
import SendBird from '../SendBird';
import styles from './followers.module.css';
import AddNewConnections from './AddNewConnections';


const FollowersPart = ({ type, subType }) => {
  
  // console.log("Type:::", type, subType);

  return (
    <main className="MainpartSetup HomeViewportHt">
       
      <Row className={`mainPad stickySidebar`}>
        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <Leftsidebar type={type} />
        </Col>

        <Col xs={12} lg={6}>
          {/* Render MyFollowers only when no specific subType is provided */}
          {type === 'followers' && !subType && <MyFollowers type={type} />}
          
          {/* Render AddNewConnections only when subType is 'add-connections' */}
          {type === 'followers' && subType === 'add-connections' && <AddNewConnections type={type} />}
        </Col>

        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <SendBird />
        </Col>

      </Row>

    </main>
    
  );
};

export default FollowersPart;
import React, { useState, useEffect } from 'react';
import { Button, Row, Image, Form, Container, Spinner } from 'react-bootstrap';
import { MdPersonAddAlt1, MdOutlineRemove } from "react-icons/md";
import baseStyles from './Chat.module.css';
import MessagingServices from '../../Services/MessagingServices';
import { FaChevronLeft } from "react-icons/fa";


const ListOfUsersforGroup = ({ openUsers, changeType, openingGroupChat, changeForm }) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    const getUserstoCreateGroup = async () => {
        
      try {
        setIsLoading(true);
        const response = await MessagingServices.fetchUsersforCreateGroup();
  
        if (response.statusCode === 200) {
          if (response.data !== null && response.data.length > 0) {
            setUsersList(response.data);
          } else {
            setErrorMessage(response.message);
          }
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.error('API Error:', error);
        setErrorMessage('An error occurred.');
      } finally {
        setIsLoading(false);
      }
    }

    if (openUsers) {
        getUserstoCreateGroup();
    }

    },[openUsers]);


    const handleSelectUser = (userId) => {
        // setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, userId]);
        
        // Check if the user is already selected
        if (!selectedUsers.includes(userId)) {
          setSelectedUsers(prevSelectedUsers => [...prevSelectedUsers, userId]);
        }
    };

    const handleRemoveUser = (userId) => {
        setSelectedUsers(prevSelectedUsers => prevSelectedUsers.filter(id => id !== userId));
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    const handleLeaveChannel = async (form) => {
      changeType("conversations");
      changeForm(form);
  }

    const createGroupChannel = async () => {
        setErrorMessage('');
        
        if(selectedUsers !== null && selectedUsers.length <= 2){
            setErrorMessage('Please select at least 2 users to create a group.');
            return;
        }
        
        if(groupName === null || groupName === "" || groupName.length === 0){
          setErrorMessage('Please enter your group name.');
          return;
        }

        const finalGroupName = groupName.trim() !== '' ? groupName : `Test_${selectedUsers.length}`;

        try {
        setIsLoading(true);
        const response = await MessagingServices.createGroupChannel(selectedUsers, finalGroupName);
        // console.log("API Response:", response);
        if (response.statusCode === 200) {
            if(response.message !== null && response.message !== undefined && 
            response.message.channel_url !== null && response.message.channel_url !== undefined){
              setTimeout(() => {
                changeType("group-chat");
                openingGroupChat(response.message.channel_url);
              }, 5000);
            } else {
              setErrorMessage(response.message || 'An error occurred while creating the group. Please try again later.');
            }
        } else {
          setErrorMessage(response.message || 'An error occurred while creating the group. Please try again later.');
        }
        
        } catch (error) {
          console.error("API Error:", error);
        }  finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 5000);
        }
    };


  return (
    
    <React.Fragment>
    {isLoading ? (
        <div className={baseStyles.CartLoadContent}>
          <Spinner animation="border" className='text-center' />
        </div>
      ) : (
    <Container className='noPadding'>
        <Row className="d-flex justify-content-center align-items-center">
        
          {errorMessage ? <div style={{ color: 'red',textAlign: 'center',fontWeight: 'bold',fontSize: '1.0em' }}>{errorMessage}</div> : null}

          <div className={`mt-1 mb-1 d-flex align-items-center flexGap`}>
              <button className="leave-channel" onClick={() => handleLeaveChannel('users')}>
                <FaChevronLeft  />
              </button>
              <Image src="/Content/Images/icons/icons-users.png"
                  roundedCircle alt="group"
                  className="roundedUser profileImage"
              />
              <Form.Control
                  id="groupNameInput"
                  type="text"
                  placeholder="Group name here"
                  className="mb-1 me-2"
                  value={groupName}
                  onChange={handleGroupNameChange}
              />
          </div>

            {usersList.length > 0 ? (
            <div className={baseStyles.UsersListdiv}>
                {usersList.map((user, index) => (
                    <ul className="list-group" key={index}>
        
                        <li key={index} className={`list-group-item search-item ${baseStyles.MsglistItem}`} onClick={() => { handleSelectUser(user._id) }} >

                            <div className="d-flex align-items-center justify-content-beteen mb-2 mt-2">
                            {user.image_url !== null && user.image_url !== "" ? (
                                        <Image
                                            src={user.image_url}
                                            roundedCircle
                                            alt="user"
                                            className="roundedUser mr-3 profileImage"
                                        />
                                        ) : (
                                        <div className="profile-image-fallback">
                                            {user.name.charAt(0)}
                                        </div>
                                        )}
                                &nbsp;&nbsp;
                            <div className="ml-3 p-1 d-flex flex-grow-1 g-2 align-items-center justify-content-between">
                                <div>
                                    <h6 className={baseStyles.liveUsername}>{user.name.split(' ')[0]}</h6>
                                    <p className={`liveUsercontent`}>{user.name}</p>
                                </div>
                            <div>
                                {selectedUsers.includes(user._id) ? (
                                    <Button className={`ml-auto ${baseStyles.SelectedUserGroup}`}
                                        onClick={() => { handleRemoveUser(user._id) }}
                                    >
                                        <MdOutlineRemove />
                                    </Button>
                                ) : (
                                    <Button className={`ml-auto ${baseStyles.SelectedUserGroup}`}
                                        onClick={() => { handleSelectUser(user._id) }}
                                    >
                                        <MdPersonAddAlt1 />
                                    </Button>
                                )}

                                </div>
                                
                            </div>
                            </div>

                        </li>
                    </ul>
                    
                ))}
            </div>

            ) : null } 

        </Row>
        <br/>
        <Row>
            
        </Row>
        <Row>
            <Button onClick={createGroupChannel} className={`mb-2 w-100 ${baseStyles.SubmitBtnStyle}`}>
              Create Group
            </Button>
        </Row>
      </Container>
      
    )}
  </React.Fragment>
  );
};


export default ListOfUsersforGroup;

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';


const PrivateRoute = () => {
  const location = useLocation();

  // State to track authentication and loading status
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Set loading state initially to true

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    // console.log("Token found in localStorage:", token); // Debug log
    if (token) {
      setIsAuthenticated(true); // Token exists, so set as authenticated
    }
    
    setIsLoading(false); // Authentication check is complete, set loading to false
  }, []);

  // Debug logs
  // console.log("isAuthenticated:", isAuthenticated);
  // console.log("isLoading:", isLoading);

  if (isLoading) {
    // Display loading spinner while checking authentication
    return (
      <div className='BeatLoaderContainerBox'>
        <BeatLoader color="#2E637D" loading={true} size={20} />
        <label>Loading...</label>
      </div>
    );
  }

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to={`/signin/form`} state={{ from: location }} replace />;
  }

  return <Outlet />; // If authenticated, render the child routes
};

export default PrivateRoute;

import { useState, useContext } from 'react';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import experienceServices from '../../Services/experienceServices';
import userServices from '../../Services/userServices';
import AuthContext from '../Context/AuthContext';


const countries = [
  { country: 'Australia', code: 'AU' },
  // { country: 'Austria', code: 'AT' },
  // { country: 'Belgium', code: 'BE' },
  // { country: 'Brazil', code: 'BR' },
  // { country: 'Bulgaria', code: 'BG' },
  // { country: 'Canada', code: 'CA' },
  // { country: 'Croatia', code: 'HR' },
  // { country: 'Cyprus', code: 'CY' },
  // { country: 'Czech Republic', code: 'CZ' },
  // { country: 'Denmark', code: 'DK' },
  // { country: 'Estonia', code: 'EE' },
  // { country: 'Finland', code: 'FI' },
  // { country: 'France', code: 'FR' },
  // { country: 'Germany', code: 'DE' },
  // { country: 'Gibraltar', code: 'GI' },
  // { country: 'Greece', code: 'GR' },
  // { country: 'Hong Kong', code: 'HK' },
  // { country: 'Hungary', code: 'HU' },
  // { country: 'India', code: 'IN' },
  // { country: 'Indonesia', code: 'ID' },
  // { country: 'Ireland', code: 'IE' },
  // { country: 'Italy', code: 'IT' },
  // { country: 'Japan', code: 'JP' },
  // { country: 'Latvia', code: 'LV' },
  // { country: 'Liechtenstein', code: 'LI' },
  // { country: 'Lithuania', code: 'LT' },
  // { country: 'Luxembourg', code: 'LU' },
  // { country: 'Malaysia', code: 'MY' },
  // { country: 'Malta', code: 'MT' },
  // { country: 'Mexico', code: 'MX' },
  // { country: 'Netherlands', code: 'NL' },
  // { country: 'New Zealand', code: 'NZ' },
  // { country: 'Norway', code: 'NO' },
  // { country: 'Poland', code: 'PL' },
  // { country: 'Portugal', code: 'PT' },
  // { country: 'Romania', code: 'RO' },
  // { country: 'Singapore', code: 'SG' },
  // { country: 'Slovakia', code: 'SK' },
  // { country: 'Slovenia', code: 'SI' },
  // { country: 'Spain', code: 'ES' },
  // { country: 'Sweden', code: 'SE' },
  // { country: 'Switzerland', code: 'CH' },
  // { country: 'Thailand', code: 'TH' },
  // { country: 'United Arab Emirates', code: 'AE' },
  // { country: 'United Kingdom', code: 'GB' },
  // { country: 'United States', code: 'US' }
];


const BankDetails = ({ isStripe }) => {

    const { setIsLoading } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedCountry, setSelectedCountry] = useState({ country: 'Australia', code: 'AU' });

    const handleSelectChange = (event) => {
      const selectedCode = event.target.value;
      const selectedCountry = countries.find(country => country.code === selectedCode);
      setSelectedCountry(selectedCountry);
    };
  
    const validateSellerAccountDetails = async () => {
      try {
        setErrorMessage("");
        if (!selectedCountry || !selectedCountry.code) {
          setErrorMessage("Please select your region");
          return;
        } else {
          setErrorMessage("");
        }
        setIsLoading(true);
        const response = await experienceServices.createSellersAccount(selectedCountry, 'profile');

        if (response.statusCode === 200) {
          if (response.data !== null && response.data[0].path !== null) {
              window.location.href = response.data[0].path;
          } else {
              setErrorMessage(response.message);
          }
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.error("API Error:", error);
        return false;
      } finally {
        setIsLoading(false);
      }
    };


    const gotoStripeDashboard = async () => {
      try {
        setIsLoading(true);

        const response = await userServices.stripeDashboard();
    
        if (response.statusCode === 200 && response.success) {
          if (response.data !== null && response.data[0].url !== null) {
            // Open the URL in a new tab
            window.open(response.data[0].url, '_blank');
            // onStripeDashboard(response.data[0].url);
          } else {
            setErrorMessage(response.message);
          }
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsLoading(false);
      }
    };
    


  return (
    <Container className='RightPadding'>
      <Row className={`d-flex flex-column align-items-center boxBody ${profileStyles.profileSectionVP}`}>
          <h5 className={`mb-3 mt-2 mainHeaderline`}>Stripe Connect </h5>
          
          <Row className='d-flex align-items-center justify-content-center'>

              <Row className='minPadding'>
                <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
                
                {isStripe ? (
                  <Row className='text-center d-flex align-items-center justify-content-center mt-5'>
                    <Col className="mt-5 d-flex align-items-center justify-content-center">
                      <button
                        className={`btn SubmitButtonSetup`}
                        onClick={gotoStripeDashboard}
                      >
                        Stripe Connect Dashboard
                      </button>
                    </Col>
                  </Row>
                ) : (
                <Row>
                  <Row className="mb-3 mt-3 d-flex justify-content-center align-items-center">
                    <Col className="d-flex flex-column align-items-center">
                      <Form.Select aria-label="Country select" disabled={isStripe} className={profileStyles.countrySelectform}  onChange={handleSelectChange}>
                        <option value="" disabled>Select the Country</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country.code}>
                            {country.country}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-5 mt-5 d-flex justify-content-center align-items-center">
                    <Col className="d-flex flex-column align-items-center">
                      <Button className={`SubmitButtonSetup`} disabled={isStripe} onClick={validateSellerAccountDetails}>
                        &nbsp; Proceed &nbsp;
                      </Button>
                    </Col>
                  </Row>
                </Row>
                )}

              </Row>
          </Row>

      </Row>
    </Container>
  );
};

export default BankDetails;
